@import "../../Styles/variables.scss";

.body {
  width: 100%;
  font-family: $sans;
  font-size: 16px;
  font-weight: $normal;
  color: $gry6;
  line-height: 1.6;

	& p {
    margin: 0;
    padding: 0 0 $spacing-standard 0;
  }

  & li {
    padding: 0 0 10px 0;
  }

  & li p {
    padding: 0;
  }

  & > ul {
    margin: 0;
    padding: 0 0 $spacing-standard 18px;
  }

  & > ul li:last-child {
    margin: 0;
    padding: 0;
  }

  & > ul > li > ul {
    margin: 0;
    padding: 10px 0 0 18px;
    list-style: none;
  }

  & > ul > li > ul > li:before {
    content: "\2013";
    margin-right: 12px;
    font-weight: $bold;
    margin-left: -20px;
  }

  & > ul > li > ul > li > ul {
    margin: 0;
    padding: 10px 0 0 18px;
    list-style: circle;
  }

  & > ol {
    margin: 0;
    padding: 0 0 $spacing-standard $container-standard-half;
  }

  & > ol li:last-child {
    margin: 0;
    padding: 0;
  }

  & > ol > li {
    margin: 0;
    padding: 0 0 10px 6px;
  }

  & > ol > li:last-child {
    margin: 0;
    padding: 0 0 0 6px;
  }

  & > ol > li > ul {
    margin: 0;
    padding: 10px 0 0 18px;
    list-style: none;
  }

  & > ol > li > ul > li:before {
    content: "\2013";
    margin-right: 12px;
    font-weight: $bold;
    margin-left: -20px;
  }

  & > ol > li > ul > li > ul {
    margin: 0;
    padding: 10px 0 0 18px;
    list-style: circle;
  }

}


h1, h2, h3, h4, h5 {
	width: 100%;
	margin: 0;
	padding: 0 0 16px 0;
	font-family: $sans;
	font-weight: 700;
	line-height: 1.1;
}

h1 {
	font-size: 44px;
}

@media (max-width: $md) {
	h1 {
		font-size: 38px;
	}
}

h2 {
	font-size: 34px;
}

h3 {
	font-size: 28px;
}

h4 {
	font-size: 22px;
}

h5 {
	font-size: 20px;
}

.footnote {
  padding: 0 0 $spacing-standard-half 0;
  color: $gry5;
  font-family: $sans;
  line-height: 1.1;
  font-weight: $normal;
  font-size: 14px;
}

.footnote.center {
  text-align: center;
}