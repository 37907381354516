@import "../../Styles/variables.scss";

.logo-animation-container {
  position: relative;
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-animation-wrapper {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.logo-animation-box {
  position: relative;
  width: 200px;
  height: 44px;
  overflow: hidden;
  opacity: 0;
}

.logo-animation-box.opacity {
  opacity: 1;
  transition: all 500ms ease;
}

.logo-animation-bar-one {
  position: absolute;
  left: 0;
  width: 0px;
  height: 44px;
  background-color: $primary;
  transition-timing-function: ease;
  z-index: 1;
}

.logo-animation-bar-one.grow {
  width: 18px;
  transition: all 500ms ease;
}

.logo-animation-bar-one.skew {
  left: 13px;
  transform: skew(-30.5deg);
  transition: all 500ms ease;
}

.logo-animation-bar-two {
  position: absolute;
  left: 26px;
  width: 0px;
  height: 44px;
  background-color: $primary;
  transition-timing-function: ease;
  z-index: 1;
}

.logo-animation-bar-two.grow {
  width: 18px;
  transition: all 500ms ease;
}

.logo-animation-bar-two.skew  {
  left: 39px;
  transform: skew(-30.5deg);
  transition: all 500ms ease;
}

.logo-animation-bar-three {
  position: absolute;
  left: 52px;
  width: 0px;
  height: 44px;
  background-color: $primary;
  transition-timing-function: ease;
  z-index: 1;
}

.logo-animation-bar-three.grow {
  width: 18px;
  transition: all 500ms ease;
}

.logo-animation-bar-three.skew {
  left: 65px;
  transform: skew(-30.5deg);
  background-color: $secondary;
  transition: all 500ms ease;
}

.logo-animation-bar-four {
  position: absolute;
  left: 78px;
  width: 0px;
  height: 44px;
  background-color: $primary;
  transition-timing-function: ease;
  z-index: 1;
}

.logo-animation-bar-four.grow {
  width: 18px;
  transition: all 500ms ease;
}

.logo-animation-bar-four.skew {
  left: 91px;
  transform: skew(-30.5deg);
  transition: all 500ms ease;
}

.logo-animation-bar-five {
  position: absolute;
  left: 104px;
  width: 0px;
  height: 44px;
  background-color: $primary;
  transition-timing-function: ease;
  z-index: 1;
}

.logo-animation-bar-five.grow {
  width: 18px;
  transition: all 500ms ease;
}

.logo-animation-bar-five.skew {
  left: 117px;
  transform: skew(-30.5deg);
  transition: all 500ms ease;
}

.logo-animation-bar-six {
  position: absolute;
  left: 130px;
  width: 0px;
  height: 44px;
  background-color: $primary;
  transition-timing-function: ease;
  z-index: 1;
}

.logo-animation-bar-six.grow {
  width: 18px;
  transition: all 500ms ease;
}

.logo-animation-bar-six.skew {
  left: 143px;
  transform: skew(-30.5deg);
  transition: all 500ms ease;
}

.logo-animation-bar-seven {
  position: absolute;
  left: 156px;
  width: 0px;
  height: 44px;
  background-color: $primary;
  transition-timing-function: ease;
  z-index: 1;
}

.logo-animation-bar-seven.grow {
  width: 18px;
  transition: all 500ms ease;
}

.logo-animation-bar-seven.skew {
  left: 169px;
  transform: skew(-30.5deg);
  transition: all 500ms ease;
}

.logo-animation-bar-eight {
  position: absolute;
  left: -26px;
  width: 0px;
  height: 44px;
  background-color: $primary;
  transition-timing-function: ease;
  z-index: 1;
}

.logo-animation-bar-eight.grow {
  width: 18px;
  transition: all 500ms ease;
}

.logo-animation-bar-eight.skew {
  left: -13px;
  transform: skew(-30.5deg);
  transition: all 500ms ease;
}

.logo-animation-background-bar-one {
  position: absolute;
  left: 0;
  width: 0px;
  height: 44px;
  background-color: $primary-dark;
}

.logo-animation-background-bar-one.grow {
  width: 18px;
  transition: all 550ms;
}

.logo-animation-background-bar-two {
  position: absolute;
  left: 26px;
  width: 0px;
  height: 44px;
  background-color: $primary-dark;
}

.logo-animation-background-bar-two.grow {
  width: 18px;
  transition: all 550ms;
}

.logo-animation-background-bar-three {
  position: absolute;
  left: 52px;
  width: 0px;
  height: 44px;
  background-color: $gry1;
}

.logo-animation-background-bar-three.grow {
  width: 18px;
  transition: all 550ms;
}

.logo-animation-background-bar-four {
  position: absolute;
  left: 78px;
  width: 0px;
  height: 44px;
  background-color: $gry1;
}

.logo-animation-background-bar-four.grow {
  width: 18px;
  transition: all 550ms;
}

.logo-animation-background-bar-five {
  position: absolute;
  left: 104px;
  width: 0px;
  height: 44px;
  background-color: $primary-dark;
}

.logo-animation-background-bar-five.grow {
  width: 18px;
  transition: all 550ms;
}

.logo-animation-background-bar-six {
  position: absolute;
  left: 130px;
  width: 0px;
  height: 44px;
  background-color: $gry1;
}

.logo-animation-background-bar-six.grow {
  width: 18px;
  transition: all 550ms;
}

.logo-animation-background-bar-seven {
  position: absolute;
  left: 156px;
  width: 0px;
  height: 44px;
  background-color: $primary-dark;
}

.logo-animation-background-bar-seven.grow {
  width: 18px;
  transition: all 550ms;
}