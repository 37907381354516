@import "../../Styles/variables.scss";

.feature-header-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 70px $container-standard;
  background: $white;
}

@media (max-width: $sm) {
  .feature-header-container {
    padding: 40px $container-standard;
  }
}

.feature-header-title {
  font-size: 52px;
  margin: 0;
  padding: 0 0 $spacing-standard-half 0;
  color: $accent;
  text-align: center;
  text-decoration: none;
  font-family: $sans;
  font-weight: $bold;
  text-transform: uppercase;
  line-height: 1;
}

.feature-header-text {
  color: $gry6;
  font-family: $sans;
  font-size: 18px;
  line-height: 1.4;
  text-align: center;
  max-width: $md;
}