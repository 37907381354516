@import "../../Styles/variables.scss";

.checkbox-wrapper {
  position: relative;
  width: 100%;
  min-height: 26px;
  display: flex;
  flex-direction: row;
  padding: 0 0 0 24px;
  margin: 0 0 10px 0;
  font-family: $sans;
  font-size: 16px;
  line-height: 1.6;
  color: $black;
  cursor: pointer;
  transition: all 250ms;
}

.checkbox-wrapper.disabled {
  cursor: not-allowed;
  color: $gry4;
}

.checkbox {
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkbox-box {
  position: absolute;
  top: 3px;
  left: 0;
  height: 20px;
  width: 20px;
  border: 2px solid $gry3;
  transition: all 250ms;
}

.checkbox-wrapper:hover input ~ .checkbox-box {
  border: 2px solid $gry4;
}

.checkbox-wrapper input:checked ~ .checkbox-box {
  border: 2px solid $accent;
}

.checkbox-wrapper input:disabled ~ .checkbox-box {
  border: 2px solid $gry2;
}

.checkbox-wrapper .checkbox-box:after {
  content: "";
  display: block;
  position: absolute;
  left: 3px;
  top: 3px;
  width: 10px;
  height: 10px;
  background-color: $white;
  transition: all 250ms;
}

.checkbox-wrapper input:hover ~ .checkbox-box:after {
  background-color: $gry3;
}

.checkbox-wrapper input:checked ~ .checkbox-box:after {
  background-color: $accent;
}

.checkbox-wrapper.disabled input ~ .checkbox-box:after {
  background-color: $white;
}

.checkbox-wrapper.disabled input:checked ~ .checkbox-box:after {
  background-color: $gry2;
}
