@import "../../Styles/variables.scss";

.header-bar {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $accent;
  margin-bottom: $spacing-standard;
}

.header-bar-title {
  padding: 0 $spacing-standard;
  color: $white;
  font-family: $sans;
  line-height: 1.1;
  text-align: center;
  font-size: 22px;
  padding: 60px 0;
}