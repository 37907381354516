@import "../../Styles/variables.scss";

.app-header {
  width: 100%;
}

.app-header .header-fixed {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  background-color: $white;
  width: 100%;
  transition: all 250ms;
  z-index: 99;
  box-shadow: $box-shadow;
  border-top: 1px solid $gry2;
}

.app-header .header-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 78px;
  background-color: $white;
  z-index: 2;
}

@media (max-width: $lg) {
  .app-header .header-wrapper {
    height: 68px;
  }
}

.app-header .header-logo-container {
  display: flex;
  flex: 1 0 auto;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 0px 0px $container-standard;
  height: 100%;
}

@media (max-width: $lg) {
  .app-header .header-logo-container {
    padding: 0px 0px 0px $container-standard-half;
  }
}

.app-header .header-logo-link {
  height: 22px;
}

.app-header .header-logo {
  width: 100px;
  height: 22px;
}

.app-header .header-buttons {
  display: flex;
  height: 100%;
}

@media (max-width: $lg) {
  .app-header .header-buttons {
    display: none;
  }
}

.app-header .header-link-clickable-area {
  position: relative;
  right: -22px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-decoration: none;
  cursor: pointer;
  transform: skew(-30.5deg,0);
  border-left: 1px solid $gry2;
  transition: all 250ms;

  &:hover {
    background-color: $gry1;
  }
}

.app-header .header-link-clickable-area.open {
  background-color: $gry1;
}

.app-header .header-dropdown-clickable-area {
  position: relative;
  right: -22px;
  width: 172px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-decoration: none;
  border-left: 1px solid $gry2;
  cursor: pointer;
  transform: skew(-30.5deg,0);
  transition: all 250ms;

  &:hover {
    background-color: $gry1;
  }
}

.app-header .header-dropdown-clickable-area.open {
  background-color: $gry1;
}

.app-header .header-links-container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  right: 0px;
  pointer-events: none;
}

.app-header .header-dropdown-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px $container-standard;
  height: 100%;
  width: 150px;
  text-decoration: none;
  pointer-events: none;
}

.app-header .header-button-link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 148px;
  padding: 0px $container-standard;
  text-decoration: none;
  pointer-events: none;
}

.app-header .header-button-link.resume,
.app-header .header-link-clickable-area.resume {
  width: 126px;
}

.app-header .header-button-link.style-guide,
.app-header .header-link-clickable-area.style-guide {
  width: 154px;
}

.app-header .header-button-text {
  color: $accent;
  text-decoration: none;
  font-family: $display;
  font-size: 14px;
  font-weight: $bold;
  text-transform: uppercase;
}

.app-header .header-dropdown {
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 0;
  top: 78px;
  right: 0px;
  width: 255px;
  max-height: calc(100vh - 98px);
  background-color: $white;
  overflow-y: hidden;
  z-index: 2;
  transition: height 250ms;
}

.app-header .header-dropdown.open {
  border-top: 1px solid $gry2;
}

.app-header .header-dropdown-link {
  position: relative;
  display: flex;
  align-items: center;
  color: $gry6;
  font-family: $sans;
  font-size: 16px;
  padding: 0 $container-standard-half;
  border-bottom: 1px solid $gry2;
  background-color: $white;
  height: 48px;
  min-height: 48px;
  text-decoration: none;
  transition: all 250ms;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: $gry1;
  }
}

.app-header .header-dropdown-icon {
  margin-left: 8px;
  font-size: 12px;
  transition: all 250ms;
}

.app-header .header-dropdown-icon.open {
  transform: rotate(180deg);
  transition: all 250ms;
}

.app-header .header-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-decoration: none;
  cursor: pointer;
  width: 68px;
  min-width: 68px;
  transition: all 250ms;

  &:hover {
    background-color: $gry1;
  }
}

.app-header .header-icon.open {
  background-color: $gry1;
}

@media (min-width: $lg) {
  .app-header .header-icon {
    display: none;
  }
}

.app-header .header-mobile-menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 68px;
  right: -255px;
  width: 255px;
  height: auto;
  max-height: calc(100vh - 98px);
  background-color: $white;
  border-top: 1px solid $gry2;
  transition: all 250ms;
  overflow-y: auto;
  z-index: 2;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $accent;
  }

  &::-webkit-scrollbar-track {
    background-color: $gry2;
  }
}

.app-header .mobile-menu {
  right: 0px;
}

.app-header .mobile-icon {
  font-size: 16px;
  transition: all 250ms;
  color: $accent;
}

@media (max-width: $lg) {
  .app-header .mobile-icon {
    font-size: 18px;
  }
}

.app-header .header-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: $overlay-color;
  transition: all 250ms;
  z-index: 1;
  animation: opacity 250ms;
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.app-header .header-faux-height {
  width: 100%;
  height: 79px;
  transition: all 250ms;
}

@media (max-width: $lg) {
  .app-header .header-faux-height {
    height: 69px;
  }
}