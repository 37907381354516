@import "../../Styles/variables.scss";

.grid {
  display: flex;
  width: 100%;
}

.grid.column {
  flex-direction: column;
}

.grid.row {
  flex-flow: row wrap;
}

@for $i from 1 through 24 {
  .grid.xs-#{$i} {
    flex: 0 0 calc(100% * (#{$i} / 24));
    max-width: calc(100% * (#{$i} / 24));
  }
}

@media (min-width: $sm) {
  @for $i from 1 through 24 {
    .grid.sm-#{$i} {
      flex: 0 0 calc(100% * (#{$i} / 24));
      max-width: calc(100% * (#{$i} / 24));
    }
  }
}

@media (min-width: $md) {
  @for $i from 1 through 24 {
    .grid.md-#{$i} {
      flex: 0 0 calc(100% * (#{$i} / 24));
      max-width: calc(100% * (#{$i} / 24));
    }
  }
}

@media (min-width: $lg) {
  @for $i from 1 through 24 {
    .grid.lg-#{$i} {
      flex: 0 0 calc(100% * (#{$i} / 24));
      max-width: calc(100% * (#{$i} / 24));
    }
  }
}

@for $i from 1 through 30 {
  .grid.row.spacing-#{$i} {
    margin-left: calc((#{$i}px / 2) * -1);
    margin-right: calc((#{$i}px / 2) * -1);
    width: calc(100% + #{$i}px);
  }
  .grid.row.spacing-#{$i} .column {
    padding: calc(#{$i}px / 2);
  }
  .grid.row.gutter-#{$i} {
    margin-left: calc((#{$i}px / 2) * -1);
    margin-right: calc((#{$i}px / 2) * -1);
    width: calc(100% + #{$i}px);
  }
  .grid.row.gutter-#{$i} .column {
    padding-left: calc(#{$i}px / 2);
    padding-right: calc(#{$i}px / 2);
  }
}
