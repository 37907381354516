@import "../../Styles/variables.scss";

.image-zoom-container {
  position: relative;
  display: block;
  width: 100%;
  transition: all 250ms;
  cursor: zoom-in;

  &:hover {
    box-shadow: $box-shadow;
    transition: all 250ms;
  }
}

.image-block {
  width: 100%;
  padding-top: 100%;
  background-position: center;
  background-size: cover;
}

.image-zoomed-overlay {
  width: 100%;
  height: 100vh;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 50%;
  left: 50%;
  background-color: $overlay-color;
  transform: translate(-50%, -50%);
  z-index: 9999;
  cursor: zoom-out;
}

.image-zoomed-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: $container-standard;
}

.image-zoomed-block {
  max-width: 100%;
  max-height: calc(100vh - (2 * $container-standard));
}