@import "../../Styles/variables.scss";

.button-filled {
  box-sizing: border-box;
  border: 0;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 52px;
  padding: 0 28px;
  margin: 0;
  font-family: $sans;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  color: $white;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  cursor: pointer;
  transition: all 250ms;
}

.button-filled.large {
  height: 62px;
}

.button-filled.wide {
  width: 100%;
}

.button-filled.reversed {
  background-color: $white;
  border: 3px solid $white;
}

.button-filled.disabled {
  background-color: $gry1;
  border-bottom: 3px solid $gry2;
  color: $gry4;
  cursor: not-allowed;
}

.button-filled.reversed.disabled {
  border: 3px solid $gry1;
  color: $gry4;
}
