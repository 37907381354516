@import "../../Styles/variables.scss";

.input-wrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.input-label {
	margin: 0 0 8px 0;
	font-family: $sans;
	font-size: 16px;
	line-height: 1;
	color: $gry5;
}

.input-wrapper.reversed .input-label {
	color: $white;
}

.input-wrapper.error .input-label {
	color: $red;
}

.input-wrapper.reversed.error .input-label {
	color: $white;
}

.input {
	box-sizing: border-box;
	width: 100%;
	height: 52px;
	padding: 10px 12px;
	border: 1px solid $gry3;
	border-radius: 5px;
	margin: 0;
	outline: 0;
	box-shadow: none;
	font-family: $sans;
	font-size: 16px;
	color: $black;
	transition: border 250ms, border-radius 250ms;
	&:hover {
		border: 1px solid $gry4;
	}
	&:disabled {
		background-color: $gry1;
		border: 1px solid $gry3;
		cursor: not-allowed;
	}
	&:active,
	&:focus {
		border: 1px solid $primary;
		border-radius: 0px;
	}
	&::placeholder {
		color: $gry3;
	}
}

.input-wrapper.error .input {
	border: 1px solid $red;
	&::placeholder {
		color: $red;
	}
}

.input-wrapper.reversed .input {
	border: none;
}

.input-explainer {
	padding: 10px 0 0 0;
	font-family: $sans;
	font-size: 14px;
	line-height: 1;
	color: $gry5;
}

.input-wrapper.error .input-explainer {
	color: $red;
}

.input-wrapper.reversed .input-explainer {
	color: $white;
}