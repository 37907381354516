@import "../../Styles/variables.scss";

.radio-wrapper {
	position: relative;
	width: 100%;
	min-height: 26px;
	display: flex;
	flex-direction: row;
	padding: 0 0 0 28px;
	margin: 0 0 10px 0;
	font-family: $sans;
	font-size: 16px;
	line-height: 1.6;
	color: $black;
	cursor: pointer;
	transition: all 250ms;
}

.radio-wrapper.disabled {
	cursor: not-allowed;
	color: $gry4;
}

.radio {
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

.radio-circle {
	position: absolute;
	top: 3px;
	left: 0;
	height: 20px;
	width: 20px;
	border: 2px solid $gry3;
	transition: all 250ms;
	border-radius: 50%;
}

.radio-wrapper:hover input ~ .radio-circle {
	border: 2px solid $gry4;
}

.radio-wrapper input:checked ~ .radio-circle {
	border: 2px solid $accent;
}

.radio-wrapper input:disabled ~ .radio-circle {
	border: 2px solid $gry1;
}

.radio-wrapper .radio-circle:after {
	content: '';
	display: block;
	position: absolute;
	left: 3px;
	top: 3px;
	width: 10px;
	height: 10px;
	background-color: $white;
	transition: all 250ms;
	border-radius: 50%;
}

.radio-wrapper input:hover ~ .radio-circle:after {
	background-color: $gry2;
}

.radio-wrapper input:checked ~ .radio-circle:after {
	background-color: $accent;
}

.radio-wrapper.disabled input ~ .radio-circle:after {
	background-color: $white;
}

.radio-wrapper.disabled input:checked ~ .radio-circle:after {
	background-color: $gry1;
}
