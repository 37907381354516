@import "../../StyleGuide/Styles/variables.scss";

.style-guide {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.style-guide .options-container {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: $gry1;
}

.style-guide .options-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: $mn;
  padding: $spacing-standard-half;
}

@media (max-width: $md) {
  .style-guide .options-wrapper {
    flex-direction: column;
  }
}

.style-guide .option {
  width: 100%;
  padding: $spacing-standard-half;
}

.style-guide .component-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 80vh;
  padding: 0 0 60px 0;
  overflow: hidden;
}

.style-guide .component-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-items: stretch;
  justify-content: center;
  padding: $spacing-standard;
  max-width: $mn;
}

.style-guide .component-wrapper.reversed {
  background-color: $accent;
}

.style-guide .component-wrapper.narrow {
  max-width: $narrow;
}
