// BREAK POINTS
$xs: 360px;
$sm: 480px;
$md: 640px;
$lg: 860px;
$mn: 1000px;
$hdr: 1440px;

// CONTENT GRIDS
$narrow:760px;
$main:1000px;
$max:1400px;

// TYPOGRAPHY
$sans: "Source Sans Pro","Helvetica Neue",Arial,Sans-serif;
$serif: utopia-std, serif;
$display: din-condensed-web,"Source Sans Pro","Helvetica Neue",Arial,Sans-serif;
$helv: "Helvetica","Source Sans Pro","Helvetica Neue",Arial,Sans-serif;
$thin: 300;
$normal: 400;
$bold: 700;

// COLORS
$primary-light:#6bd2fc;
$primary:#28a1ea;
$primary-dark:#006db7;

$secondary-light:#f950ad;
$secondary:#ef258a;
$secondary-dark:#bc1772;

$accent-light:#2f617f;
$accent:#193c56;
$accent-dark:#0d273d;

$red: #fc5151;
$red-dark: #e52828;
$orange: #f99b3e;
$orange-dark: #ea711f;
$yellow: #fffa50;
$yellow-dark: #e8d700;
$green: #56d156;
$green-dark: #2cb42c;
$blue: #58cff9;
$blue-dark: #20aad3;
$purple: #b06cff;
$purple-dark: #7e48e5;

$white: #ffffff;
$gry1: #f1f1f1;
$gry2: #dedede;
$gry3: #bcbcbc;
$gry4: #888888;
$gry5: #505050;
$gry6: #222222;
$black: #000000;

// SPACING
$container-standard: 32px;
$container-standard-half: 16px;
$spacing-standard: 24px;
$spacing-standard-half: 12px;
$box-shadow: 0 0 20px 0 rgba(25,60,86,0.15);
$overlay-color: rgba(25,60,86,0.5);


:export {
  // BREAK POINTS
  xs: $xs;
  sm: $sm;
  md: $md;
  lg: $lg;
  mn: $mn;
  hdr: $hdr;
  // CONTENT GRIDS
  narrow: $narrow;
  main: $main;
  max: $max;
  // TYPOGRAPHY
  sans: $sans;
  serif: $serif;
  display: $display;
  helv: $helv;
  thin: $thin;
  normal: $normal;
  bold: $bold;
  // COLORS
  primaryLight: $primary-light;
  primary: $primary;
  primaryDark: $primary-dark;

  secondaryLight: $secondary-light;
  secondary: $secondary;
  secondaryDark: $secondary-dark;

  accentLight: $accent-light;
  accent: $accent;
  accentDark: $accent-dark;

  red: $red;
  redDark: $red-dark;
  orange: $orange;
  orangeDark: $orange-dark;
  yellow: $yellow;
  yellowDark: $yellow-dark;
  green: $green;
  greenDark: $green-dark;
  blue: $blue;
  blueDark: $blue-dark;
  purple: $purple;
  purpleDark: $purple-dark;

  containerStandard: $container-standard;
  containerStandardHalf: $container-standard-half;
  spacingStandard: $spacing-standard;
  spacingStandardHalf: $spacing-standard-half;
  boxShadow: $box-shadow;
  overlayColor: $overlay-color;

  white: $white;
  gry1: $gry1;
  gry2: $gry2;
  gry3: $gry3;
  gry4: $gry4;
  gry5: $gry5;
  gry6: $gry6;
  black: $black;
}