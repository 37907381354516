@import "../../Styles/variables.scss";

.colors-container {
  width: 100%;
  max-width: $mn;
  display: flex;
  flex-wrap: wrap;
}

.colors-wrapper {
  width: 16.66%;
  padding: $spacing-standard-half;
}

@media (max-width: $mn) {
  .colors-wrapper {
    width: 25%;
  }
}

@media (max-width: $md) {
  .colors-wrapper {
    width: 50%;
  }
}

@media (max-width: $sm) {
  .colors-wrapper {
    width: 100%;
  }
}

.grys-wrapper {
  width: 25%;
  padding: $spacing-standard-half;
}

@media (max-width: $md) {
  .grys-wrapper {
    width: 50%;
  }
}

@media (max-width: $sm) {
  .grys-wrapper {
    width: 100%;
  }
}

.colors-shadow {
  display: flex;
  flex-direction: column;
  box-shadow: $box-shadow;
}

.color {
  width: 100%;
  height: 140px;
}

.color-information {
  width: 100%;
  padding: $spacing-standard-half;
}

.color-hex {
  width: 100%;
  font-family: $sans;
  line-height: 1;
  font-size: 14px;
  font-weight: $bold;
  padding: 0 0 5px 0;
}

.color-name {
  width: 100%;
  font-family: $sans;
  line-height: 1;
  font-size: 12px;
  font-weight: $normal;
}

.line {
  width: 100%;
  border-top: 1px solid $gry2;
  margin: $spacing-standard $spacing-standard-half;
}
