@import "../../StyleGuide/Styles/variables.scss";

.homepage-container {
  position: relative;
  width: 100%;
  height: calc(100vh - 79px);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $accent;
  overflow: hidden;
}

@media (max-width: $lg) {
  .homepage-container {
    height: calc(100vh - 69px);
  }
}

.first-text {
  position: absolute;
  padding: 0 $container-standard;
  color: $white;
  text-align: center;
  text-decoration: none;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 42px;
  font-weight: $bold;
  text-transform: uppercase;
  line-height: 1;
  user-select: none;
}

.homepage-first-container {
  position: absolute;
  width: calc(100vw + 100vh);
  height: calc(100vh);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  transition: 2500ms all;
  left: calc((-100vw + -100vh) - (100vh * 0.589 / 2));

  &.slide {
    left: calc(-50vh * 0.589);
  }

  &.disable-transitions {
    transition: 0ms all;
  }
}

.homepage-first-color {
  width: calc(100vw + 100vh);
  height: calc(100vh);
  background-color: $primary-dark;
  transform: skew(-0deg);

  &.slide {
    transform: skew(-30.5deg);
  }
}

.homepage-second-container {
  position: absolute;
  width: calc(100vw + 100vh);
  height: calc(100vh);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  transition: 2500ms all;
  left: calc((-100vw + -100vh) - (100vh * 0.589 / 2));

  &.slide {
    left: calc(-50vh * 0.589);
  }

  &.disable-transitions {
    transition: 0ms all;
  }
}

.homepage-second-color {
  width: calc(100vw + 100vh);
  height: calc(100vh);
  background-color: $primary;
  transform: skew(-0deg);

  &.slide {
    transform: skew(-30.5deg);
  }
}

.homepage-third-container {
  position: absolute;
  width: calc(100vw + 100vh);
  height: calc(100vh);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  transition: 2500ms all;
  left: calc((-100vw + -100vh) - (100vh * 0.589 / 2));

  &.slide {
    left: calc(-50vh * 0.589);
  }

  &.disable-transitions {
    transition: 0ms all;
  }
}

.homepage-third-color {
  width: calc(100vw + 100vh);
  height: calc(100vh);
  background-color: $primary-light;
  transform: skew(-0deg);

  &.slide {
    transform: skew(-30.5deg);
  }
}

.homepage-fourth-container {
  position: absolute;
  width: calc(100vw + 100vh);
  height: calc(100vh);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  transition: 2500ms all;
  left: calc((-100vw + -100vh) - (100vh * 0.589 / 2));

  &.slide {
    left: calc(-50vh * 0.589);
  }

  &.disable-transitions {
    transition: 0ms all;
  }
}

.homepage-fourth-color {
  width: calc(100vw + 100vh);
  height: calc(100vh);
  background-color: $white;
  transform: skew(-0deg);

  &.slide {
    transform: skew(-30.5deg);
  }
}

.second-text {
  position: absolute;
  padding: 0 $container-standard;
  color: $accent;
  text-align: center;
  text-decoration: none;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 42px;
  font-weight: $bold;
  text-transform: uppercase;
  line-height: 1;
  z-index: 1;
  transition: 1500ms all;
  opacity: 0;
  user-select: none;

  &.fade {
    opacity: 1;
  }

  &.fadeout {
    transition: 500ms all;
    opacity: 0;
  }
}

.homepage-wrapper {
  position: relative;
  width: 200px;
  height: 0px;
  overflow: hidden;
  transition: 500ms all;
  opacity: 0;

  &.resize {
    height: 44px;
    opacity: 1;
  }
}

.final-content-wrapper {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 2;
}

.homepage-content-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: $mn;
  padding: $container-standard;
  transition: 2500ms all;
  opacity: 0;

  &.fade {
    opacity: 1;
  }
}

.homepage-line {
  width: calc(100% - 24px);
  height: 1px;
  background-color: $gry2;
  margin: 0 0 $spacing-standard-half;
}

.homepage-links-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.homepage-link,
.homepage-bullet {
  position: relative;
  text-decoration: none;
  font-family: $sans;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  color: $accent;
  line-height: 1;
  transition: all 250ms ease 0s;
}

.homepage-bullet {
  padding: $spacing-standard-half 0;
}

.homepage-link {
  cursor: pointer;
  padding: $spacing-standard-half;
}