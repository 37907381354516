@import "../../Styles/variables.scss";

.dropdown-wrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.dropdown-wrapper.disabled {
	cursor: not-allowed;
}

.dropdown-label {
	margin: 0 0 8px 0;
	font-family: $sans;
	font-size: 16px;
	line-height: 1;
	color: $gry5;
}

.dropdown-wrapper.error .dropdown-label {
	color: $primary;
}

.dropdown-wrapper.reversed .dropdown-label {
	color: $white;
}

.dropdown-bar {
	display: flex;
	align-items: center;
	position: relative;
	width: 100%;
	height: 52px;
	padding: 12px 16px;
	border: 1px solid $gry2;
	border-radius: 5px;
	background-color: $white;
	font-family: $sans;
	font-size: 16px;
	color: $gry6;
	transition: border 250ms, border-radius 250ms;
	cursor: pointer;
	&:hover {
		border: 1px solid $gry3;
	}
	&:active,
	&:focus {
		border: 1px solid $primary;
	}
	&.open {
		border: 1px solid $primary;
		border-radius: 0;
	}
}

.dropdown-wrapper.error .dropdown-bar {
	border: 1px solid $primary;
}

.dropdown-wrapper.reversed .dropdown-bar {
	border: 1px solid $white;
}

.dropdown-wrapper.disabled .dropdown-bar {
	background-color: $gry1;
	border: 1px solid $gry2;
	color: $gry4;
	pointer-events: none;
}

.dropdown-wrapper.reversed.disabled .dropdown-bar {
	border: 1px solid $gry1;
}

.dropdown-icon-container {
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 0;
	right: 0;
	width: 46px;
	height: 46px;
	transition: all 250ms;
}

.dropdown-icon {
	font-size: 14px;
	color: $primary;
	transition: all 250ms;
}

.dropdown-bar.open .dropdown-icon {
	transform: rotate(180deg);
}

.dropdown-wrapper.disabled .dropdown-icon {
	color: $gry3;
}

.dropdown-list {
	position: relative;
	width: 100%;
}

.dropdown-items {
	position: absolute;
	top: 0;
	left: 0;
	height: 0;
	max-height: 270px;
	width: 100%;
	background-color: $white;
	overflow-y: auto;
	z-index: 5;
	transition: all 250ms;
	&::-webkit-scrollbar {
		width: 5px;
	}
	&::-webkit-scrollbar-thumb {
		background-color: $accent;
	}
	&::-webkit-scrollbar-track {
		background-color: $gry2;
	}
}

.dropdown-items.open {
	box-shadow: 0 8px 20px 0 rgba(25,60,86,0.15);
}

.dropdown-item {
	display: flex;
	align-items: center;
	width: 100%;
	height: 48px;
	border-bottom: 1px solid $gry2;
	font-family: $sans;
	font-size: 16px;
	line-height: 1.2;
	padding: 12px 16px;
	transition: background-color 250ms;
	cursor: pointer;

	&:hover {
		background-color: $gry1;
	}

	&:last-child {
		border-bottom: none;
	}
}

.dropdown-item.selected {
	font-weight: $bold;
	color: $accent;
	background-color: $gry1;
}

.dropdown-item.disabled {
	background-color: $gry1;
	cursor: not-allowed;
}

.dropdown-no-result {
	display: flex;
	align-items: center;
	width: 100%;
	height: 52px;
	border-left: 1px solid $gry2;
	font-family: $sans;
	font-size: 16px;
	line-height: 1.2;
	padding: 10px 12px;
}