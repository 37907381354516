@import "../../Styles/variables.scss";

.button-outline {
  box-sizing: border-box;
  border: 0;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 52px;
  padding: 0 28px;
  margin: 0;
  font-family: $sans;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  color: $white;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  cursor: pointer;
  transition: all 250ms;
}

.button-outline.large {
  height: 62px;
}

.button-outline.wide {
  width: 100%;
}

.button-outline.reversed {
  background-color: $white;
  border: 3px solid $white;
}

.button-outline.disabled {
  background-color: transparent;
  border: 3px solid $gry3;
  color: $gry3;
  cursor: not-allowed;
}

.button-outline.reversed.disabled {
  background-color: transparent;
  border: 3px solid $white;
  color: $white;
  opacity: 0.7;
  cursor: not-allowed;
}
