.hate-hero {
  width: 100%;
  background-color: #0d273d;
  height: 300px;
}

.hate-herocopy {
  width: 345px;
  display: block;
  margin: auto;
}

.hate-rows {
  display: flex;
  border-bottom: 1px solid #dedede;
  padding-top: 18px;
}

.hate-final-row {
  display: flex;
  padding-top: 18px;
}

.hate-numbers {
  flex: 8%;
}

.hate-words {
  flex: 92%;
  padding-left: 18px;
}

.numberlist {
  text-align: right;
  color: #bbbbbb;
  font-size: 18px;
  font-family: 'Source Sans Pro', Helvetica, sans-serif;
}

.hate-copy {
  font-family: 'Source Sans Pro', Helvetica, sans-serif;
  text-align: left;
  font-size: 18px;
  color: #4c4c4c;
  padding-bottom: 10px;
  line-height: 1.2rem;
}

.more-hate {
  color: #bbbbbb;
  font-size: 20px;
  font-family: 'Source Sans Pro', Helvetica, sans-serif;
}

.hate-list {
  padding-top: 8px;
  width: 345px;
  display: block;
  margin: auto;
  padding-bottom: 30px;
  position: relative;
}

.hate-red {
  color: #fc5151;
}

.hate-header {
  font-family: 'Source Sans Pro', Helvetica, sans-serif;
  font-weight: bold;
  color: #fff;
  font-size: 28px;
  text-align: left;
  padding-top: 100px;
  margin: 0px;
  vertical-align: middle;
}

.bodycopy {
  font-size: 18px;
  padding-bottom: 30px;
  line-height: 1.2;
  color: #fff;
}


@charset "UTF-8";

.animatedheader {
  -webkit-animation-duration: 1s;
  animation-duration: 3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-delay: .5s;
  animation-delay: .5s;
}
.animatedsubhead {
  -webkit-animation-duration: 1s;
  animation-duration: 3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}
.animatedbody {
  -webkit-animation-duration: 1s;
  animation-duration: 3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
}
.animatedbodyextended {
  -webkit-animation-duration: 1s;
  animation-duration: 3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-delay: 6.5s;
  animation-delay: 6.5s;
}
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}


@-webkit-keyframes bounceInUp {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }

  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }

  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes bounceInUp {
  from, 60%, 75%, 90%, to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }

  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }

  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.bounceInUp {
  -webkit-animation-name: bounceInUp;
  animation-name: bounceInUp;
}


@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@-webkit-keyframes fadeInUpBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUpBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUpBig {
  -webkit-animation-name: fadeInUpBig;
  animation-name: fadeInUpBig;
}
